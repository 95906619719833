@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.App {
  text-align: center;
}
*,
body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#About p {
  padding-top: 25px;
}

.plus-icon span {
  border-radius: 50%;
  color: rgb(255, 255, 255);
  font-weight: 700;
  background-color: rgb(45, 83, 111);
  height: 16px;
  width: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 5px 0px 0px;
}

.houseImage {
  width: 500px;
}

.image-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: 0 0 15px 0 !important;
}
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* For 16:9 aspect ratio. Adjust as needed */
}

.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.people-image {
  width: 520px;
  height: 520px;
  object-fit: cover;
  border-bottom: #2d536f 8px solid;
  margin-right: 10px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  padding: 0px 40px 40px 40px;
}

.outter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ebebeb;
  padding: 50px 0 50px 0;
}

@media (max-width: 768px) {
  .image-text {
    display: flex;
    flex-direction: column;
  }
  .houseImage {
    width: 350px;
  }

  .people-image {
    width: full;
    object-fit: cover;
    border-bottom: #2d536f 8px solid;
    margin-right: 10px;
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 40px 40px 40px;
  }
  .outter-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    background-color: #ebebeb;
    padding: 50px 0 50px 0;
  }
}
